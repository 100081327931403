var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text ml-1"},[_c('PnxLogo')],1)]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto register-page",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('registration.welcome'))+" Phoenix Native 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('registration.signup-msg'))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.pnxRegister($event)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com","autocomplete":"username"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('profile.usertitle'),"label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('profile.nameexample'),"autocomplete":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":_vm.$t('stats.country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length == 0 || 'is-invalid',attrs:{"label":_vm.$t('stats.country'),"label-for":"i-country"}},[_c('v-select',{attrs:{"id":"i-country","placeholder":_vm.$t('sites.choosecountry'),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.target_countries,"label":"text"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length == 0 || 'is-invalid',attrs:{"label":_vm.$t('registration.accountcurrency'),"label-for":"i-currency"}},[_c('v-select',{attrs:{"id":"i-currency","placeholder":_vm.$t('registration.selectcurrency'),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.currencyList,"label":"text"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('profile.phoneexample'),"label-for":"register-phone"}},[_c('validation-provider',{attrs:{"name":"Phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone","name":"register-phone","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('profile.phoneexample')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('page.contacts.title')}},_vm._l((_vm.contacts),function(contact,index){return _c('div',{key:index,staticClass:"contacts mb-1"},[_c('validation-provider',{attrs:{"name":_vm.socials[contact.type].text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:{
                      'is-invalid': errors.length > 0,
                    }},[_c('b-input-group-prepend',[_c('b-dropdown',{attrs:{"variant":"outline-secondary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-img',{staticClass:"align-middle",attrs:{"width":"16","height":"16","src":_vm.socials[contact.type].src}})]},proxy:true}],null,true)},_vm._l((Object.keys(_vm.socials)),function(key,idx){return _c('b-dropdown-item',{key:idx,on:{"click":function($event){return _vm.contactTypeUpdate(key, index)}}},[_c('b-img',{attrs:{"width":"19","height":"19","src":_vm.socials[key].src}}),_vm._v(" "+_vm._s(_vm.socials[key].text)+" ")],1)}),1)],1),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.socials[contact.type].text},model:{value:(_vm.contacts[index].value),callback:function ($$v) {_vm.$set(_vm.contacts[index], "value", $$v)},expression:"contacts[index].value"}}),_c('b-input-group-append',[(index === 0)?_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.contactAdd()}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon"}})],1):_vm._e(),(index > 0)?_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.contactRemove(index)}}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"}})],1):_vm._e()],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}),0),_c('b-form-group',{attrs:{"label":"Promocode","label-for":"register-promocode"}},[_c('b-form-input',{attrs:{"id":"register-promocode","name":"promocode","placeholder":"Promocode"},model:{value:(_vm.promocode),callback:function ($$v) {_vm.promocode=$$v},expression:"promocode"}})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":_vm.$t('registration.password')}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············","autocomplete":"new-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('registration.confirmpassword'),"label-for":"i-c-password"}},[_c('validation-provider',{attrs:{"name":"Re-type Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length == 0 || 'is-invalid'},[_c('b-form-input',{attrs:{"id":"i-c-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldTypeRetype,"placeholder":"············","autocomplete":"confirm-password"},model:{value:(_vm.RetypePassword),callback:function ($$v) {_vm.RetypePassword=$$v},expression:"RetypePassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('privacypolicy.title'),"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","state":errors.length > 0 ? false:null,"name":"iagreeto"},model:{value:(_vm.iagreeto),callback:function ($$v) {_vm.iagreeto=$$v},expression:"iagreeto"}},[_vm._v(" "+_vm._s(_vm.$t('privacypolicy.iagreeto'))+" "),_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-privacypolicy",modifiers:{"modal-privacypolicy":true}}]},[_vm._v(" "+_vm._s(_vm.$t('privacypolicy.link_text'))+" ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('registration.signup'))+" ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('registration.haveaccount'))+"?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('registration.signin')))])]),_c('b-navbar-nav',{staticClass:"nav align-items-center mt-2"},[_c('locale')],1)],1)],1)],1)],1),_c('b-modal',{ref:"modal-pp",attrs:{"id":"modal-privacypolicy","scrollable":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('privacypolicy.title'))+" ")]},proxy:true},{key:"default",fn:function(){return [_c('privatpolicyText')]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.cencel()}}},[_vm._v(" "+_vm._s(_vm.$t('form.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.ok()}}},[_vm._v(" "+_vm._s(_vm.$t('Accept'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }